<template>
    <div>
        <div v-if="examinationsList.length > 0">
            <el-form v-for="(item,index) in examinationsList" :key="index" label-width="100px" style="border-bottom: 1px solid #999;">
                <el-form-item label="提交人：" style="margin-bottom: 0;">
                    {{item.userName}}
                </el-form-item>
                <el-form-item label="问题描述：" style="margin-bottom: 0;">
                    {{item.confirmProblem}}
                </el-form-item>
                <el-form-item label="所属规范：" style="margin-bottom: 0;">
                    {{item.drStandardName}}
                </el-form-item>
                <el-form-item label="所属条文：" style="margin-bottom: 0;">
                    {{item.drStandardItemName}}
                </el-form-item>
                <el-form-item label="图纸位置：" style="margin-bottom: 0;">
                    {{item.drProjectFilePath}} 
                    <el-link v-if="item.positionImg && item.positionImg.length > 0" type="primary" @click="checkScreenshots(item.positionImg)">查看截图</el-link>
                </el-form-item>
                <el-form-item label="审查内容：" style="margin-bottom: 0;">
                    {{item.drAuditContentName}}
                </el-form-item>
                
            </el-form>
        </div>
        <div v-if="!examinationsList.length > 0">
            <div style="text-align: center;">暂无数据</div>
        </div>

        <el-dialog
        title="图纸截图"
        :visible.sync="showScreenshotVisible"
        v-model="showScreenshotVisible"
        :close-on-click-modal="false"
        append-to-body="true"
        width='70%'
        >
            <div>
                <img :src="positionImg" width="100%" height="100%" />
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="showScreenshotVisible = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getPreliminaryTrialOpinionListByPrProjectId,getReviewOpinionList } from '@/api/api'
export default {
    data() {
        return {
            examinationsList: [
                {
                    confirmProblem: "",
                    confirmProblem1: "",
                    drStandardItemName: "",
                    confirmProblem2: "",
                    drAuditContentName: "",
                    userName:"",
                },
            ],
            showScreenshotVisible: false,
            positionImg:'',
        }
    },
    props:['projectId','isFirst', 'taskId'],
    methods: {
        // 图审人员初审意见
        primaryExaminations() {
            var params = {
                projectId: this._props.projectId
            }
            if(this._props.isFirst == 1) {
                getPreliminaryTrialOpinionListByPrProjectId(params).then(res => {
                    var result = res.data
                    if(result.success && result.response) {
                        this.examinationsList = result.response
                    }else{
                        this.examinationsList = []
                    }
                })
            } else {
                params.taskId = this._props.taskId;
                getReviewOpinionList(params).then(res => {
                    var result = res.data
                    if(result.success && result.response) {
                        this.examinationsList = result.response
                    }else{
                        this.examinationsList = []
                    }
                })
            }
        },
        // 查看截图
        checkScreenshots(positionImg) {
            this.showScreenshotVisible = true;
            this.positionImg = positionImg;
        },
    },
    watch: {
        'projectId': function() {
            this.primaryExaminations()
        }
    },
    created(){
        this.primaryExaminations()
    }
}
</script>

<style lang="stylus" scoped>

</style>